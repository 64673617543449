<template>
    <div class="row">
        <div class="col-7" id="bg-register">
            <img src="@/assets/images/img_register.png" alt="img_register">
        </div>
        <div class="col-md-5">

            <div class="d-flex align-items-center" style="min-height: 100vh;">
                <div class="col-sm-10 col-md-10 col-lg-10 mx-auto mt-2" style="min-width: 300px;">
                    <div class="card navbar-shadow" v-if="!finished">
                        <div class="card-header text-center">
                            <div class="media">
                                <div class="media-body"><h4 class="card-title">Realizar Cadastro</h4>
                                    <p class="card-subtitle text-50">
                                        Insira as informações para realizar o cadastro
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">

                            <button type="button" class="btn mb-16pt btn-light btn-block" @click="redirectToGoogle"><span
                                class="fab fa-google mr-2"></span>
                                Cadastrar com Goolge
                            </button>

                            <div class="page-separator justify-content-center">
                                <div class="page-separator__text bg-white">ou</div>
                            </div>

                            <div class="form-group">
                                <router-link type="submit" class="btn btn-primary btn-block" to="/checkout">Iniciar Cadastro</router-link>
                            </div>
                            <!--
                            <button type="button" class="btn mb-16pt btn-light btn-block"><span
                                class="fab fa-google mr-2"></span>
                                Cadastrar com Goolge
                            </button>
                            <div class="page-separator justify-content-center">
                                <div class="page-separator__text bg-white">ou</div>
                            </div>
                            <form novalidate="novalidate" class="" id="form" @submit.prevent="save">

                                <div role="group" class="form-group">
                                    <label for="name" class="d-block form-label">Nome Completo:
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="bv-no-focus-ring">
                                        <div role="group" class="input-group input-group-merge form-control-prepended">
                                            <input id="name" type="text" placeholder="Nome Completo" required="required"
                                                   aria-required="true" name="name" v-model="formData.name"
                                                   class="form-control form-control-prepended">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <span class="fas fa-user"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div role="group" class="form-group">
                                    <label for="email" class="d-block form-label">E-mail:
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="bv-no-focus-ring">
                                        <div role="group" class="input-group input-group-merge form-control-prepended">
                                            <input id="email" type="email" placeholder="E-mail" required="required"
                                                   aria-required="true" name="email" v-model="formData.email"
                                                   class="form-control form-control-prepended">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <span class="fas fa-envelope"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary btn-block">Cadastrar</button>
                                </div>
                            </form>
                            -->
                        </div>
                        <div class="card-footer">
                            <div class="text-center text-black-50">
                                Já é Mentor?
                                <router-link to="/login">
                                    Login
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="card navbar-shadow mt-2" v-else>
                        <div class="col text-center my-5 f-100">
                            <i class="fa fa-check-circle text-success"></i>
                        </div>
                        <div class="col text-center mb-5">
                            <h3>Cadastro realizado com sucesso!</h3>
                            <p>Você irá receber em seu e-mail de cadastro um link para cadastrar sua senha.</p>
                        </div>
                    </div>
                </div>
            </div>
            <SpinnerXl v-if="showSpinner"/>
        </div>
    </div>
</template>

<style scoped>

.f-100 {
    font-size: 100px;
}

</style>

<script>
import SpinnerXl from "@/components/base/spinner-xl.vue";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {ValidateForm} from "@/components/composables/cruds";
import http from "@/http/index";
import env from "@/env";

export default {
    components: {
        SpinnerXl
    },
    data() {
        return {
            title: 'Realizar Cadastro',
            finished: false,
            showSpinner: false,
            formData: {
                name: '',
                email: ''
            }
        }
    },
    methods: {

        save() {
            this.showSpinner = true
            startLoading('form', 'save');
            const required = {
                name: 'Nome',
                email: 'E-mail'
            };
            if (!ValidateForm(this.$root, this.formData, required)) {
                endLoading('form', 'save');
                this.hideSpinner();
                return;
            }

            http.post('mentor', this.formData)
                .then(() => {
                    this.finished = true;
                    this.$notifySuccess('Cadastro realizado com sucesso!');
                })
                .catch((error) => {
                    console.error('Mentor Register: ' + error);
                    this.$notifyError(error);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.hideSpinner();
                })
        },

        hideSpinner() {
            setTimeout(() => {
                this.showSpinner = false;
            }, 300)
        },

        redirectToGoogle() {
            window.location.href = env.url + 'login/google?type=mentor&v=';
        },
    }
}
</script>
